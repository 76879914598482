<template>
  <div class="lotteryIndex">
    <Header></Header>
    <div class="listContent">
      <div class="time_box">
        <span @click="backIndex">返回抽奖页面</span>
        <span v-if="this.getUserInfo.additional.luck_num|| this.getUserInfo.additional.luck_num ==0">剩余：{{ this.getUserInfo.additional.luck_num}}次</span>
      </div>
      <div class="list_box" v-if="dataList.length > 0">
        <div
          :class="
            index % 2 == 0 ? 'single_list_gray single_list' : 'single_list'
          "
          v-for="(item, index) in dataList"
          :key="index"
        >
          <div class="list_left">
            <div class="list_date">{{ item.time }}</div>
            <div class="list_name">{{ item.prize.name }}</div>
          </div>
          <div class="list_right" @click="closePoster(1, item)">分享</div>
        </div>
        <!-- <div class="single_list">
          <div class="list_left">
            <div class="list_date">11-11 12:12:10</div>
            <div class="list_name">现金红包66.6</div>
          </div>
          <div class="list_right">分享</div>
        </div>
        <div class="single_list_gray single_list">
          <div class="list_left">
            <div class="list_date">11-11 12:12:10</div>
            <div class="list_name">现金红包66.6</div>
          </div>
          <div class="list_right">分享</div>
        </div> -->
      </div>
      <div class="empty_box" v-else>
        <img :src="emptyIcon" alt="" class="emptyIcon" />
        <div class="empty_text">暂无抽奖记录~~</div>
      </div>
    </div>
    <Poster
      v-if="showShareInfo"
      @closePoster="closePoster"
      :shareInfo="shareInfo"
    ></Poster>
  </div>
</template>
<script>
import Vue from "vue";
import { Icon, Toast } from "vant";
import Header from "./components/header.vue";
import { mapGetters } from "vuex";
import emptyIcon from "@/assets/images/empty.png";
import Poster from "./components/poster.vue";

Vue.use(Icon);
Vue.use(Toast);
export default {
  components: {
    Header,
    Poster,
  },
  data() {
    return {
      showShareInfo: false,
      emptyIcon,
      dataList: [],
      shareInfo: {},
    };
  },
  created() {
    this.getUserRewardsList();
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
  },
  methods: {
    // 关闭分享海报
    closePoster(value, item) {
      if (value) {
        this.showShareInfo = true;
      } else {
        this.showShareInfo = false;
      }
      if (item) {
        this.shareInfo.name = item.prize.name;
        this.shareInfo.level = item.prize.level.name;
        this.shareInfo.images = item.prize.images;
      }
    },
    // 格式化时间
    formatDate(date, fmt) {
      if (date == "Invalid Date") {
        return null;
      }
      let o = {
        "M+": date.getMonth() + 1, // 月份
        "d+": date.getDate(), // 日
        "h+": date.getHours(), // 小时
        "m+": date.getMinutes(), // 分
        "s+": date.getSeconds(), // 秒
        S: date.getMilliseconds(), // 毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    },
    // 获取抽奖记录
    async getUserRewardsList() {
      const res = await this.$api.lottery.getUserRewardsList({});
      if (res.errorCode != 1000) {
        Toast({
          message: res.errorMessage,
        });
      } else {
        this.dataList = res.data;
        this.dataList.map((item) => {
          let reg = new RegExp("-", "g");
          let time = item.created_at.replace(reg, "/");
          item.time = this.formatDate(new Date(time), "MM-dd hh:mm:ss");
        });
      }
    },
    // 返回抽奖页面
    backIndex() {
      this.$router.push('/choujiang/lottery/index');
    },
  },
};
</script>
<style lang="less" scoped>
.lotteryIndex {
  box-sizing: border-box;
  overflow-y: scroll;
  // height: 100vh;
  .listContent {
    min-height: 100vh;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-top: 88px;
    background: #ffffff;
    position: relative;
    .empty_box {
      padding-top: 100px;
      .emptyIcon {
        width: 150px;
      }
      .empty_text {
        font-size: 14px;
        margin-top: 10px;
        color: #838c8f;
      }
    }
    .list_box {
      overflow-y: scroll;
      .single_list {
        box-sizing: border-box;
        width: 100%;
        height: 66px;
        background: #ffffff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 36px 0 16px;
        line-height: 66px;

        .list_left {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          height: 100%;
          width: calc(100% - 45px);
          box-sizing: border-box;
        }
        .list_date {
          font-size: 14px;
          color: #838c8f;
          height: 100%;
          width: 110px;
          text-align: left;
        }
        .list_name {
          font-size: 16px;
          color: #0f2128;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: calc(100% - 110px);
          box-sizing: border-box;
          text-align: left;
        }
        .list_right {
          font-size: 16px;
          color: #838c8f;
          width: 45px;
          word-break: normal;
          text-align: right;
        }
      }
      .single_list_gray {
        background: #f8f9fc;
      }
    }
    .time_box {
      box-sizing: border-box;
      width: 100%;
      height: 45px;
      position: fixed;
      left: 0;
      top: 44px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;
      color: #838c8f;
      line-height: 45px;
      padding: 0 16px;
      z-index: 99;
      background: #ffffff;
    }
    .shake_icon {
      width: 123.5px;
      height: 119px;
      margin-bottom: 23px;
    }
    .shake_text {
      font-size: 16px;
      color: #E96346;
    }
  }
}
</style>
